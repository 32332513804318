* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 1px solid rgb(229, 231, 235);
  border-radius: 20px;
  background-color: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(17, 25, 39, 0.04);
}

.login_logo {
  width: 100%;
  height: 120px;
}

.logo_container {
  padding: 60px 0px 0px;
}

.burgerMenu {
  margin-left: 20px;
  margin-top: 5px;
}

.table-row-cursor-pointer {
  cursor: pointer;
}

a {
  cursor: pointer;
}

/* mui-dataTbles cutom style */
.tss-qbo1l6-MUIDataTableToolbar-actions {
  display: none !important;
}

.table-head {
  text-transform: none !important;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  text-align: center !important;
}

.pagination>div {
  padding: 8px 10px;
}

.pagination>svg {
  right: 4px;
}

.custom-search .Mui-focused {
  box-shadow: #6366f1 0 0 0 1px !important;
}

.custom-search .search-text {
  border-width: 2px !important;
}

.custom-search .search-text input {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  padding-left: 2px !important;
  font-size: 1rem !important;
}

.custom-search .search-text div>div {
  padding: 0px !important;
  margin-right: 2px !important;
  margin-top: 0px !important;
}

.mui-datatables table {
  font-size: 2px !important;
  /* Adjust the font size as needed */
}

/* query builder cutom style */
.topBox {
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
}

/* Styling for individual rules */
.filter-icon {
  border-width: 2px !important;
  border-radius: 8px !important;
}

.filter-icon:hover {
  border-width: 2px !important;
}

.react-datepicker__input-container input {
  width: 100% !important;
}

.filterRadiobtn {
  display: inline-block !important;
}

.queryButtonBox {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
}

.btnbox {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: 10px;
}

.action {
  text-align: center !important;
}

.capitalize {
  text-transform: capitalize;
}

.add-btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  gap: 8px;
}

.segmentQueryBuilder .ruleGroup-header,
.segmentQueryBuilder .rule-remove {
  display: none !important;
}

.segmentQueryBuilder .ruleGroup-body .rule::before {
  content: none !important;
}

.segmentQueryBuilder .ruleGroup-body .rule::after {
  content: none !important;
}

.menuIcon {
  display: none !important;
}

.sidebarDrawer:hover .menuIcon {
  display: block !important;
}

.MuiSelect-nativeInput {
  display: none;
}

.multiSelectChip div div {
  padding-top: 12.5px !important;
  padding-bottom: 12.5px !important;
}

.queryBuilder .multiselectFocused>div>div {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.contactForm {
  background-color: transparent !important;
  padding-top: 30px !important;
  padding-bottom: 20px !important;
  box-shadow: none !important;
}

.reportsHeader {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.7rem 0.5rem 0 !important;
}

.css-1vajahv {
  clear: both !important;
}

.modalAction {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  margin-top: 6px;
  padding-bottom: 4px;
}

.align-left {
  text-align: left !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.th-text th {
  text-transform: capitalize !important;
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
}

.tox-tinymce {
  overflow: visible !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.customQueryBuilderDate {
  display: flex;
  gap: 16px;
  width: 100%;
}

.customQueryBuilderDate .dateInput {
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
}

.contracts_table tr td:nth-child(3) {
  max-width: 400px !important;
  word-wrap: break-word;
}

.ModalFormButtons {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  margin-top: 4px;
}

/* ////////////////////////////////////////////////////////////////////////////////////////// */

/* Query Builder css start */

.queryBuilder .ruleGroup {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: none;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 10px;
  flex-direction: column-reverse !important;
}

.queryBuilder .ruleGroup .ruleGroup-header {
  gap: 1.2rem;
  align-items: first baseline;
}

.queryBuilder .ruleGroup .ruleGroup-header button.ruleGroup-addRule,
.queryBuilder .ruleGroup .ruleGroup-header button.ruleGroup-addGroup,
.queryBuilder .ruleGroup .ruleGroup-header .ruleGroup-remove {
  background-color: #e7e7e6;
  color: #6366f1;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.03px;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  border: none;
}

.queryBuilder .ruleGroup .ruleGroup-header button.ruleGroup-addRule:hover,
.queryBuilder .ruleGroup .ruleGroup-header button.ruleGroup-addRule:active,
.queryBuilder .ruleGroup .ruleGroup-header button.ruleGroup-addRule:focus,
.queryBuilder .ruleGroup .ruleGroup-header button.ruleGroup-addGroup:hover,
.queryBuilder .ruleGroup .ruleGroup-header button.ruleGroup-addGroup:focus,
.queryBuilder .ruleGroup .ruleGroup-header button.ruleGroup-addGroup:active,
.queryBuilder .ruleGroup .ruleGroup-header .ruleGroup-remove:hover,
.queryBuilder .ruleGroup .ruleGroup-header .ruleGroup-remove:active,
.queryBuilder .ruleGroup .ruleGroup-header .ruleGroup-remove:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.queryBuilder .ruleGroup .rule {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.queryBuilder .ruleGroup .ruleGroup-header .ruleGroup-combinators,
.queryBuilder .ruleGroup .betweenRules .ruleGroup-combinators,
.queryBuilder .ruleGroup .ruleGroup-body .rule .rule-fields,
.queryBuilder .ruleGroup .ruleGroup-body .rule .rule-operators,
.queryBuilder .ruleGroup .ruleGroup-body .rule .rule-value {
  padding: 8px 70px 8px 18px;
  border-color: #6366f1;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  box-shadow: none;
  outline: none;
  box-shadow: none;
  outline: none;
  border: 2px solid #6366f1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background-image: url("../../public/down-chevron.png");
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: right 12px center;
}

.queryBuilder .ruleGroup .ruleGroup-body .rule .autoComplete,
.queryBuilder .ruleGroup .ruleGroup-body .rule .multiSelectChip {
  background-color: #fff;
  border-radius: 10px;
}

.queryBuilder .ruleGroup .ruleGroup-body {
  background-color: #efeffe;
  padding: 20px;
  border-radius: 10px;
}

.queryBuilder .ruleGroup .ruleGroup-body .rule .rule-remove {
  background-color: transparent;
  /* background-color: #ccc; */
  border: 1px solid transparent;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  padding: 5px 10px;
  /* padding-bottom: 10px; */
}

.queryBuilder .ruleGroup .ruleGroup-header .ruleGroup-remove {
  color: #cc304c;
  background-color: #fff;
}

.queryBuilder .ruleGroup[data-level="0"] .ruleGroup-header+.ruleGroup-body {
  background-color: #fff;
}

.queryBuilder .ruleGroup[data-level="1"] .ruleGroup-header+.ruleGroup-body {
  background-color: transparent;
}

.queryBuilder .ruleGroup[data-level="1"] {
  background-color: #efeffe;
  box-shadow: none;
}

.queryBuilder .ruleGroup[data-level="1"] .ruleGroup-header .ruleGroup-addGroup {
  display: none;
}

.queryBuilder .ruleGroup[data-level="1"] .ruleGroup-header button.ruleGroup-addRule,
.queryBuilder .ruleGroup[data-level="1"] .ruleGroup-header button.ruleGroup-addGroup,
.queryBuilder .ruleGroup[data-level="1"] .ruleGroup-header .ruleGroup-combinators,
.queryBuilder .ruleGroup[data-level="1"] .ruleGroup-body .betweenRules .ruleGroup-combinators {
  background-color: #fff;
}

.queryBuilder .ruleGroup[data-level="1"] .ruleGroup-header {
  margin-bottom: 10px;
}

.queryBuilder .ruleGroup .ruleGroup-body .rule .rule-fields,
.queryBuilder .ruleGroup .ruleGroup-body .rule .rule-operators,
.queryBuilder .ruleGroup .ruleGroup-body .rule .rule-value {
  box-shadow: none;
  border: 1px solid #e7e7e6;
  width: 100%;
  background-color: #fff;
  padding: 15px 20px 15px 15px;
}

.queryBuilder .ruleGroup .ruleGroup-body .rule .rule-fields:focus,
.queryBuilder .ruleGroup .ruleGroup-body .rule .rule-operators:focus,
.queryBuilder .ruleGroup .ruleGroup-body .rule .rule-value:focus {
  box-shadow: none;
  border-color: transparent;
  outline: 3px solid #6366f1;
}

.queryBuilder .ruleGroup .betweenRules .ruleGroup-combinators {
  margin-bottom: 10px;
}

.queryBuilder .multiSelectChip svg,
.MuiInputBase-root svg {
  width: 0px;
  height: 0px;
}

.showSvgImage .MuiInputBase-root svg {
  width: 1em !important;
  height: 1em !important;
}

.multiSelectChip .MuiSelect-multiple,
.MuiSelect-select,
.MuiAutocomplete-input,
select.MuiNativeSelect-select {
  position: relative !important;
  background-image: url("../../public/down-chevron.png") !important;
  background-size: 15px 15px !important;
  background-repeat: no-repeat !important;
  background-position: right 12px center !important;
}

.tss-5jmwmz-MUIDataTableFooter-root .MuiInputBase-root .MuiSelect-select {
  background-size: 10px 10px !important;
}

.queryBuilder .ruleGroup .ruleGroup-body .rule .rule-value[type="text"],
.queryBuilder .ruleGroup .ruleGroup-body .rule .rule-value[type="email"],
.queryBuilder .ruleGroup .ruleGroup-body .rule .rule-value[type="password"],
.queryBuilder .ruleGroup .ruleGroup-body .rule .rule-value[type="number"] {
  background-image: none !important;
}

.queryBuilder .dateInput svg,
.autoSelect button[title="Clear"] svg,
.autoComplete button[title="Clear"] svg,
.datepicker svg,
.timepicker svg {
  width: 1em !important;
  height: 1em !important;
}

.autoSelect div,
.autoComplete>div>div {
  padding-right: 0px !important;
}

.autoSelect button,
.autoComplete button {
  margin-right: 12px !important;
}

.queryBuilder .ruleGroup .ruleGroup-body .rule .customQueryBuilderDate .dateInput input {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.queryBuilder .ruleGroup .ruleGroup-body .rule .customQueryBuilderDate .dateInput>div {
  padding: 3.45px 15px 3.45px 5px !important;
  border-radius: 10px !important;
}

.hasAutoSelectLable .MuiAutocomplete-input {
  background-position: right 12px top !important;
}

.hasAutoSelectLable button {
  margin-top: 0 !important;
}

/* This css for dark theme of querybuilder */
.builderParent[data-theme="dark"] .queryBuilder .ruleGroup {
  background-color: rgb(42, 58, 89);
}

.builderParent[data-theme="dark"] .queryBuilder .ruleGroup[data-level="0"] .ruleGroup-body {
  background-color: rgb(82, 104, 147);
}

.builderParent[data-theme="dark"] .queryBuilder .ruleGroup .ruleGroup-body .rule .rule-fields,
.builderParent[data-theme="dark"] .queryBuilder .ruleGroup .ruleGroup-body .rule .rule-operators,
.builderParent[data-theme="dark"] .queryBuilder .ruleGroup .ruleGroup-body .rule .rule-value,
.builderParent[data-theme="dark"] .queryBuilder .ruleGroup .betweenRules .ruleGroup-combinators {
  background-color: rgb(42, 58, 89);
  color: #fff;
  background-image: url("../../public/down-chevron-dark.png");
}

.builderParent[data-theme="dark"] .queryBuilder .ruleGroup .ruleGroup-body .rule .autoComplete,
.builderParent[data-theme="dark"] .queryBuilder .ruleGroup .ruleGroup-body .rule .autoComplete,
.builderParent[data-theme="dark"] .queryBuilder .ruleGroup .ruleGroup-body .rule .customQueryBuilderDate .dateInput {
  background-color: rgb(42, 58, 89);
  color: #fff;
}

.builderParent[data-theme="dark"] .queryBuilder .ruleGroup[data-level="1"] .ruleGroup-header button.ruleGroup-addRule,
.builderParent[data-theme="dark"] .queryBuilder .ruleGroup[data-level="1"] .ruleGroup-header button.ruleGroup-addGroup {
  background-color: #fff;
}

.builderParent[data-theme="dark"] .queryBuilder .ruleGroup[data-level="1"] .ruleGroup-header .ruleGroup-combinators {
  background-color: rgb(42, 58, 89);
  color: #fff;
}

.builderParent[data-theme="dark"] .queryBuilder .ruleGroup .ruleGroup-header .ruleGroup-remove {
  color: #cc304c;
}